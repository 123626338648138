<template>
	<v-app id="app">
		<custom-snack-bar ref="snackBar" @snackBarClosed="snackBarClosed"></custom-snack-bar>
		<sidebar-menu ref="sidebar"></sidebar-menu>

		<v-app-bar app color="primary">
			<v-app-bar-nav-icon icon @click="ToggleSidebar" dark></v-app-bar-nav-icon>
			<v-toolbar-title class="pl-xs-0" v-if="$vuetify.breakpoint.mdAndUp || isUserAuthenticated">
				<router-link to="/">
					<v-img src="@/assets/img/NutriyouLogoHome.png" contain height="100"
						width="140"></v-img></router-link>
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<div class="grow text-right mr-5" v-if="$vuetify.breakpoint.mdAndUp">
				<router-link to="/Nutritionists" class="white--text mx-5" v-if="!isUserInRoles(['Patient'])">I
					nutrizionisti di
					Nutriyou</router-link>
				<router-link to="/FAQ" class="white--text mx-5">FAQ</router-link>
				<router-link to="/ContactUs" class="white--text mx-5">{{ $t("common.contactUs") }}</router-link>
			</div>
      <template v-if="isUserInRoles(['Patient', 'Nutritionist'])">
        <router-link to="/Chat" class="mr-2">
        <v-badge color="red" v-if="TotalNewMessages > 0">
          <template v-slot:badge>
            <span>{{ TotalNewMessages }}</span>
          </template>
          <v-icon color="white">fas fa-fw fa-comment-alt-dots</v-icon>
        </v-badge>
        <v-icon color="white" v-else>fas fa-fw fa-comment-alt-dots</v-icon>
        </router-link>
      </template>
			<v-btn v-if="!isUserAuthenticated" color="white" text :large="$vuetify.breakpoint.mdAndUp" to="/Login"
				class="mr-3">Accedi</v-btn>
			<v-btn color="white" :large="$vuetify.breakpoint.mdAndUp" v-if="!isUserAuthenticated"
				to="/Trova-il-tuo-Nutrizionista">Vai al Questionario</v-btn>
		</v-app-bar>

		<!-- Sizes your content based upon application components -->
		<v-main class="" :class="{ 'pb-0': isChatWindowActive && $vuetify.breakpoint.xs }">
			<!-- Provides the application the proper gutter -->
			<v-container fluid class="mb-0 pa-0"
                   :class="{ 'pa-0': $vuetify.breakpoint.xsOnly}"
                   style="min-height: 500px">
				<!-- If using vue-router -->
				<router-view :key="$route.fullPath"></router-view>
			</v-container>

			<v-footer color="primary pa-10 grey--text text--lighten-2" v-if="!(isChatWindowActive && $vuetify.breakpoint.xs)">
				<v-container class="limitWidth" fluid>
					<v-row dense>
						<v-col>
							<v-img src="@/assets/img/LogoFooter.png" contain width="200"></v-img>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" md="4">
							<router-link class="grey--text text--lighten-2" to="/ContactUs">{{ $t("common.contactUs") }}</router-link>
							<br />
							<router-link class="grey--text text--lighten-2" to="/Blog">{{ $t("common.blog") }}</router-link>
						</v-col>
						<v-col cols="12" md="4">
							<router-link class="grey--text text--lighten-2" to="/PrivacyGoogleCalendar">Privacy Policy</router-link>
							<br />
							<router-link class="grey--text text--lighten-2" to="/TermsAndConditionsGoogleCalendar">Termini e Condizioni</router-link>
							<br />
							<router-link class="grey--text text--lighten-2" to="/ContactUs">Cookie Policy</router-link>
						</v-col>
						<v-col cols="12" md="4">
              <div class="mb-2">
                <span class="text-overline">Seguici su:</span>

                <br />
                <v-btn icon href="https://www.instagram.com/nutriyou_it/" target="_blank" class="mr-5">
                  <v-icon large color="white">fab fa-instagram</v-icon></v-btn>

                <v-btn icon href="https://www.facebook.com/NUTRIyou.it" target="_blank">
                  <v-icon large color="white ml-4">fab fa-facebook</v-icon></v-btn>
              </div>
              <div>
                <span class="text-overline">Assistenza:</span>
                <br />
                <v-btn color="#25D366" icon href="https://wa.me/3342993235"  target="_blank">
                  <v-icon large color="#25D366">fab fa-whatsapp</v-icon>
                </v-btn>
              </div>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" class="pa-3 text-center text-body-2 grey--text text--lighten-2">
							<div
								class="text-center poppins-regular mt-2"
								style="font-size: 0.7rem"
							>
								Nutriyou SRL - Cap.Soc. € 10.000 i.v. - P.IVA IT04159440363
								<br/>
								Copyright © 2025 - Tutti i diritti riservati
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-footer>
		</v-main>
	</v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CustomSnackBar from "./components/Shared/Common/CustomSnackBar.vue";
import SidebarMenu from "./components/Layout/SidebarMenu.vue";

export default {
	metaInfo() {
		return {
			title: "Nutriyou",
			// titleTemplate: "%s - Yay!",
			htmlAttrs: {
				lang: "it",
			},
			meta: [
				{ charset: "utf-8" },
				{ name: "description", content: this.$t("pageMetaDescription") },
			],
		};
	},
	components: {
		"custom-snack-bar": CustomSnackBar,
		SidebarMenu,
	},
	watch: {
		chatNotificationRedirectUrl: function (val) {
			this.$router.push(val);
		},
	},
	methods: {
		...mapActions(["snackResetNotification"]),

		ToggleSidebar() {
			this.$refs.sidebar.ToggleDrawer();
		},

		CloseSidebar() {
			this.$refs.sidebar.showDrawer = false;
		},

		snackBarClosed() {
			this.snackResetNotification();
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters([
			"UserProfile",
			"selectedApplicationId",
			"isUserInRoles",
			"isUserAuthenticated",
			"TotalNewMessages",
			"isChatWindowActive",
			"chatNotificationRedirectUrl"
		]),
	},
	mounted() {
		this.$log.debug(
			"Who am I: " +
			(this.isUserAuthenticated ? this.UserProfile.Id : "not signed in")
		);

		this.$store.watch(
			(state) => state.notifications.snack,
			(newValue) => {
				if (newValue.Text !== "") {
					this.$refs.snackBar.showNotification({
						Text: this.$store.state.notifications.snack.Text,
						Color: this.$store.state.notifications.snack.Color,
						Header: this.$store.state.notifications.snack.Header,
						Icon: this.$store.state.notifications.snack.Icon,
					});
				}
			}
		);
	},
};
</script>

<style lang="scss">
@import "@/assets/css/AppStyle.scss";
@import "fontawesome-pro/css/all.css";

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
<style lang="scss" scoped>
.limitWidth {
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
}
</style>

<i18n>
{
	"it":{
		"pageTitle": "Nutriyou - Il nutrizionista online su misura per te!",
		"pageMetaDescription": "Trova il nutrizionista online su misura per te"
	}
}
</i18n>
